import React from 'react'
import {projects} from '../../Database/metaData'
import Card from '../cards'
import './Project.css'
function Project() {
    let ongoingProjects = projects.Ongoing;
    let completedProjects = projects.Completed;
    let project =(data)=>{
        return data.map((cardData)=>{
          console.log(cardData)
            return <Card title={cardData.name} summary = {cardData.place} image={cardData.img} big={false}/>
        })
    }

    return (
      <>
        <div className="container-fluid project mb-3">
            <div className='h2 row ms-5'>Ongoing Projects</div>
            <div className="row row-cols-1 row-cols-md-5 g-4">
                {project(ongoingProjects)}
            </div>
            <div className='h2 row mt-5 ms-5'>Completed Projects</div>
            <div className="row row-cols-1 row-cols-md-5 g-4">
                {project(completedProjects)}
            </div>
        </div>
      </>
    );
}

export default Project
