import React from "react";
import architech from "../../assets/images/architect-service.jpg";

import "./services.css";
function OurServices() {
  return (<>
  <div className="container-fluid mb-5 display">
      <h1 className="service-header text-center">
        Turning your vision into reality starts here
      </h1>
      <div className="row">
        <div className="col-lg-12">
          <div className="service-section service-odd">
            <div className="service-bg"></div>
            <div className="service-item">
              <picture>
                <img src="./architect-service.jpg" alt="Architectural Designing &planning" />
              </picture>
              <div className="service-content">
                <h3>Architectural Designing & planning</h3>
                <p>
                  Architectural planning and designing are crucial parts of
                  every construction project planning stage. Discuss with
                  professionals (Architect/Engineer) about your needs,
                  requirement, budget ...etc. You could get detailed Plans,
                  Elevations and Sectional drawings for your dream home.
                </p>
                <p>
                List of drawings you get,
                <ul>
                  <li>  Site Plan Drawings </li>
                  <li> Floor Plan Drawings</li>
                  <li> Cross Section Drawings</li>
                  <li> Elevation Drawings</li>
                  <li>Working Plan </li>
                  <li>Landscape Drawing(If req.) </li>
                </ul>
                </p>
              </div>
            </div>
          </div>
          <div className="service-section service-even">
            <div className="service-bg"></div>
            <div className="service-item">
              <picture>
                <img
                  src="./buldingConstruction.webp" 
                  alt="Building construction and Contractors"
                />
              </picture>
              <div className="service-content">
                <h3>Building construction and Contractors</h3>
                <p>
                  Sparrow nest construction solutions for all
                  construction-related services under one umbrella. If you
                  searching for a reliable and experienced building contractor
                  in Hosur, Bangalore, and Nagercoil your search ends here. We
                  provide high-quality of all construction services on time.
                </p>
                <ul>
                  <li>15 years waranty for structures</li>
                  <li>6months general waranty for plumbing,electrical and flooring</li>
                  <li>no hidden cost</li>
                  <li>ontime delivery</li>
                </ul>
                <p>
                customer-focused aproach our goal.
                <ul>
                Customer satisfaction is our priority. We started this company after listening to multiple customers' pain points. We decided to solve it and provide the best quality service.

                </ul>
                </p>

              </div>
            </div>
          </div>
          <div className="service-section service-odd">
            <div className="service-bg"></div>
            <div className="service-item">
              <picture>
                <img src="./ProjectplanningandConsulting.webp"  alt="Project planning and consulting" />
              </picture>
              <div className="service-content">
                <h3>Project planning and consulting</h3>
                <p>
                  Anyone can build a home if they have a dream and interest. But
                  if you feel, your home should be strong enough from generation
                  to generation, you need technical guidance and support from
                  expert professionals. we provide that support to you just if
                  you are being with us enough. we taking care of your project.
                  we assure the quality of construction at every stage from
                  design to handover
                </p>
              </div>
            </div>
          </div>
          <div className="service-section service-even">
            <div className="service-bg"></div>
            <div className="service-item">
              <picture>
                <img src="./STRUCTURALDESIGN.webp"  alt="Structural Design & Consulting" />
              </picture>
              <div className="service-content">
                <h3>Structural Design &Consulting</h3>
                <p>
                The structural design gives life to the structures. As a
                structural consultants, we designed the bones and muscles of
                the structures. Give the detail of column(pillar), beam,
                foundation, basement, slab size, and strength and stability.
                This process assures buildings durability and strength and
                safety.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="displayProgress" ><h3 style={{"marginTop":"100px","marginBottom":"450px","marginLeft":"20px"}}>Please Visit this site in laptop/desktop to get more details on this page.</h3></div>
  </> 
    
  );
}

export default OurServices;
