import React from "react";
import Card from '../components/cards'
import {knowledge} from '../Database/metaData'
function Knowledge() {
  let knowledgeCardMapping =()=>{
      let data = knowledge.knowledge;
      return data.map((cardData)=>{
          return <Card title={cardData.title} summary = {cardData.summary} image={cardData.img} big={true}/>
      })
  }
  return (
    <>
      <div className="container-fluid" style={{marginTop:"2em"}}>
          <div className="">
              {knowledgeCardMapping()}
          </div>
      </div>
    </>
  );
}

export default Knowledge;
