import { useEffect, useState } from "react";
function CostCalculator() {
  const [formValue, setFormValue] = useState({
    area: 0, type: 0
  });
  const [submitData, setSubmitData] = useState({});
  const [total, setTotal] = useState(0);
  const [defaultMessage, setMessage] = useState(0);
  const [errorMessage, setErrorMessage] = useState("")
  const mapValue = {
    parking_area: 1747,
    sump_ltr: 22,
    recycle_water: 34000,
    compound_wallsize: 1900,
    solar_power: 75000,
  };
  function handleChange(e) {
    const target = e.target;
    setFormValue({
      ...formValue,
      [target.name]: mapValue[target.name]
        ? mapValue[target.name] * target.value
        : target.value,
    });
  }
  useEffect(() => {
    let data = { ...formValue };
    delete data.area;
    data.type = parseInt(formValue.area) * parseInt(formValue.type);
    let totalValue = Object.values(data).reduce(
      (a, b) => parseInt(a) + parseInt(b)
    );
    console.log(totalValue)
    setTotal(totalValue ? totalValue : 0);
    setMessage(`Dear sir/madam
    Thanks for using our cost calculator. Please enter your details to send the cost estimation to your mail.

    Total Summary
    Construction area in sq.ft. ${formValue.area}
    Select construction package (Basic) ${formValue.type}
    Car parking area in square feet? (optional) ${formValue.parking_area}
    How many litres of underground sump required? (optional) ( 264000 x 22 ) ${formValue.sump_ltr}
    How many person capacity waste water recycling tank required? (optional) ( 102000 x 34000 ) ${formValue.recycle_water}
    How much feet length compound wall do you require? (optional) ${formValue.compound_wallsize}
    How much solar power do you require? (optional) ( 300000 x 75000 ) ${formValue.solar_power}

    Total: ${total}
    Our customer support team will get in touch with you.
    Thanks in advance
    `);
  }, [formValue]);

  function updateField (e) {
    const target = e.target;
    setSubmitData({
      ...submitData,
      [target.name]: target.value,
    });
  }
  async function handleSubmit () {
    let message = ""
    if (!submitData.name) {
      message = message  +"<li>Enter your name</li>"
    }
    if (!submitData.email?.match("^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$")) {
      message = message  +"<li>Invalid email id</li>"
    } else if (!submitData.email) {
      message = message  +"<li>Enter Email address</li>"
    }
    if(submitData.mobile && !submitData.mobile.match(/^\d{10}$/)) {
      message = message +"<li>Invalid mobile number</li>"
    } else if (!submitData.mobile) {
      message = message  +"<li>Enter mobile number</li>"
    }
    setErrorMessage(`<ul class="error-list">${message}</ul>`)
  }
  return (
    <div className="container mb-5">
      <div className="row mb-3">
        <div className="col-12 text-center">
          <h1>Construction cost calculator</h1>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 col-lg-6 mb-4">
          <div className="calc-div">
            <h4>House construction cost calculator</h4>
            <form
              className="calc-wrapper form"
              onSubmit={(e) => e.preventDefault()}
            >
              <label htmlFor="area">Construction area in sq.ft.</label>
              <input
                type="number"
                name="area"
                min="0"
                onChange={handleChange}
              />
              <label htmlFor="type">Select construction package</label>
              <select defaultValue="0" name="type" onChange={handleChange}>
                <option value="">--Select value--</option>
                <option value="1999">Standard</option>
                <option value="1850">Basic</option>
                <option value="2299">Premium</option>
              </select>
              <label htmlFor="parking_area">
                Car parking area in square feet? (optional).
              </label>
              <input
                onChange={handleChange}
                type="number"
                min="0"
                defaultValue="0"
                name="parking_area"
              />
              <span>
                car parking will be charged @Rs.1750/sft. approximately
              </span>
              <label htmlFor="sump_ltr">
                <div>
                  How many litres of underground sump required? (optional)
                </div>
                <div>
                  {formValue.sump_ltr &&
                    formValue.sump_ltr / mapValue["sump_ltr"]}
                </div>
              </label>
              <input
                type="range"
                name="sump_ltr"
                min="0"
                step="2000"
                onChange={handleChange}
                max="20000"
                defaultValue="0"
              />
              <span>
                An average family of 4 will require a minimum of 10,000 litres
              </span>
              <label htmlFor="recycle_water">
                <div>
                  How many person capacity waste water recycling tank required?
                  (optional)
                </div>
                <div>
                  {formValue.recycle_water &&
                    formValue.recycle_water / mapValue.recycle_water}
                </div>
              </label>
              <input
                onChange={handleChange}
                type="range"
                min="0"
                name="recycle_water"
                max="10"
                defaultValue="0"
              />
              <span>
                Waste water recycling tank is an alternative to septic tank. It
                recycles the waste water & recharges underground water table.
                It's not required if you have a drainage connection
              </span>
              <label htmlFor="compound_wallsize">
                How much feet length compound wall do you require? (optional)
              </label>
              <input
                type="number"
                onChange={handleChange}
                defaultValue=""
                min="0"
                name="compound_wallsize"
              />
              <span>
                If plot size is 40X30, compound wall length will be
                40+40+30+30=140 feet
              </span>
              <label defaultValue="solar_power">
                <div>How much solar power do you require? (optional)</div>
                <div>
                  {formValue.solar_power &&
                    formValue.solar_power / mapValue.solar_power}
                </div>
              </label>
              <input
                type="range"
                min="0"
                onChange={handleChange}
                name="solar_power"
                max="10"
                defaultValue="0"
              />
              <span>
                A 3BHK home will require 2KW solar power to run fans,lights and
                television during the daytime
              </span>
            </form>
          </div>
        </div>
        <div className="col-md-12 col-lg-6 mb-2">
          <div className="calc-div">
            <h4>Total Summary</h4>
            <table className="mt-4">
              <tbody>
                <tr>
                  <td>Construction Area In Sq.Ft.</td>
                  <td>{formValue.area ? formValue.area : 0}</td>
                </tr>
                <tr>
                  <td>Select Construction Package</td>
                  <td>{formValue.type ? formValue.type : 0}</td>
                </tr>
                <tr>
                  <td>Car Parking Area In Square Feet? (Optional)</td>
                  <td>{formValue.parking_area ? formValue.parking_area : 0}</td>
                </tr>
                <tr>
                  <td>
                    How Many Litres Of Underground Sump Required? (Optional)
                  </td>
                  <td>{formValue.sump_ltr ? formValue.sump_ltr : 0}</td>
                </tr>
                <tr>
                  <td>
                    How Many Person Capacity Waste Water Recycling Tank
                    Required? (Optional)
                  </td>
                  <td>
                    {formValue.recycle_water ? formValue.recycle_water : 0}
                  </td>
                </tr>
                <tr>
                  <td>
                    How Much Feet Length Compound Wall Do You Require?
                    (Optional)
                  </td>
                  <td>
                    {formValue.compound_wallsize
                      ? formValue.compound_wallsize
                      : 0}
                  </td>
                </tr>
                <tr>
                  <td>How Much Solar Power Do You Require? (Optional)</td>
                  <td>{formValue.solar_power ? formValue.solar_power : 0}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td>Total</td>
                  <td>{total}</td>
                </tr>
              </tfoot>
            </table>
            <button
              className="submit-btn mt-3 d-none"
              type="button"
              data-toggle="modal"
              data-target="#priceModal"
            >
              Submit
            </button>

            <div id="priceModal" className="modal fade price-modal" role="dialog">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h4 className="modal-title">Submit Your Summary</h4>
                    <div
                      className="close-price_modal"
                      data-dismiss="modal"
                    >
                      &times;
                    </div>
                  </div>
                  <div className="modal-body">
                    <form className="calc-wrapper form">
                      <label htmlFor="name">Your Name</label>
                      <input type="text" name="name" onChange={updateField} />
                      <label htmlFor="email">Your Email</label>
                      <input type="email" name="email" onChange={updateField} />
                      <label htmlFor="mobile">Your Mobile</label>
                      <input
                        type="mobile"
                        name="mobile"
                        onChange={updateField}
                      />
                      <label htmlFor="message">Your Message</label>
                      <textarea
                        rows="10"
                        name="message"
                        defaultValue={defaultMessage}
                        onChange={updateField}
                      />
                    </form>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-secondary"
                      data-dismiss="modal"
                    >
                      Cancel
                    </button>
                    <button type="button" className="btn btn-primary" onClick={handleSubmit}>Submit</button>
                  </div>
                  <div dangerouslySetInnerHTML={{__html:errorMessage}}></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CostCalculator;
