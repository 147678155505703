import avatar from "../../assets/images/avatar.svg";

function CustomerReview() {
  return (
    <div className="container mb-5">
      <div className="row text-center">
        <div className="col-12">
          <h1>WE SERVE WITH 100% CUSTOMER SATISFACTION</h1>
          <div
            id="carouselExampleIndicators"
            className="carousel slide"
            data-ride="carousel"
          >
            <ol className="carousel-indicators">
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="0"
                className="active"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="1"
              ></li>
              <li
                data-target="#carouselExampleIndicators"
                data-slide-to="2"
              ></li>
            </ol>
            <div className="carousel-inner  customer-review ">
              <div className="carousel-item active">
                <div className="customer-rev-div">
                  <p>
                    Me, being an NRI faced issues with the heat of Chennai,water
                    scarcity etc. They showed me how they could solve my
                    problems. We did the project together they were able to
                    execute it, they were thorough, they communicated regularly
                    and after completion the building looks stunning
                  </p>
                  <div className="user-info">
                    <img src={avatar} alt="user image" />
                    <h5>Shiva</h5>
                  </div>
                </div>
              </div>
              <div className="carousel-item">
                <div className="customer-rev-div">
                  <p>
                    Initially we took a lot of time in deciding the plan, there
                    were a lot of changes & modifications from our side in
                    finalising the plan even after the construction began, but
                    they were very patient and made changes for us. Patience is
                    a virtue not many has, we found Hireandbuild team to be
                    patient and customer oriented. Our house has came out great.
                    We are a joint family with elders too, glad to say that we
                    all are satisfied
                  </p>
                  <div className="user-info">
                    <img src={avatar} alt="user image" />
                    <h5>Guru Akash</h5>
                  </div>
                </div>
              </div>
              {/* <div className="carousel-item">
                <div className="customer-rev-div">
                  <p>
                    Me, being an NRI faced issues with the heat of Chennai,water
                    scarcity etc. They showed me how they could solve my
                    problems. We did the project together they were able to
                    execute it, they were thorough, they communicated regularly
                    and after completion the building looks stunning
                  </p>
                  <div className="user-info">
                    <img src={avatar} alt="user image" />
                    <h5>Harsha</h5>
                  </div>
                </div>
              </div> */}
            </div>
            <a
              className="carousel-control-prev"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="prev"
            >
              <span className="sr-only-prev">
                <i className="fas fa-angle-left"></i>
              </span>
            </a>
            <a
              className="carousel-control-next"
              href="#carouselExampleIndicators"
              role="button"
              data-slide="next"
            >
              <span className="sr-only-next">
                <i className="fas fa-angle-right"></i>
              </span>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}
export default CustomerReview;
