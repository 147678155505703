import React from "react";
import "./card.css";

function cards(props) {
  const { title, summary, image, big } = props;
  return (
    <>
      {big ? (
        <div className="card mb-4 w-auto">
          <div className="row g-0">
            <div className="col-md-4">
              <img
                src={image ? image : "./logo.jpg"}
                className="img-fluid rounded-start"
                width={"350rem"}
                height={"100rem"}
                alt="..."
              />
            </div>
            <div className="col-md-8">
              <div className="card-body">
                <h5 className="card-title">{title}</h5>
                <p className="card-text">{summary}</p>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className="card ms-5">
          <img
            src={image ? image : "./logo.jpg"}
            className="card-img-top img-fluid rounded-start mt-2 "
            alt="..."
            style={{"aspectRatio":"1"}}
          />
          <div className="card-body">
            <h5 className="card-title">{title}</h5>
          </div>
          <div className="card-footer">
            <small className="text-muted">{summary}</small>
          </div>
        </div>
      )}
    </>
  );
}

export default cards;

// <a href="#" className="btn btn-primary">
//               Go somewhere
//             </a>
