import React from "react";
import "./Pricing.css";
import CostCalculator from "./CostCalculator";
import icon from "../../assets/images/icon.svg";
import premium from '../../assets/images/PREMIUM.jpg'
import CustomerReview from "./CustomerReview";
import {pricingContent} from '../../Database/metaData'
function Pricing() {
  console.log(pricingContent);
  let listItems = (item)=>{
    return <li>{item}</li>
  }
  let mappingItems = (pricingContent)=>{
    return pricingContent.map((data)=>{
    return (<div className="spec-toggle">
        <div
          className="spec-header collapsed"
          data-toggle="collapse"
          href={"#"+data.headName+"Content"}
          role="button"
          aria-expanded="false"
          aria-controls={data.headName+"Content"}
        >
          <h5>{data.headName}</h5>
          <div className="toggle-div show">+</div>
          <div className="toggle-div hide">-</div>
        </div>
        <div
          className="row collapse multi-collapse"
          id={data.headName+"Content"}
        >
          <div className="col-md-4">
            <ul className="mt-3">
              <b className="d-none headerDisplay">BASIC</b>
              {data.firstData.map((item)=> <li key={item}>{item}</li>)}
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="mt-3">
            <b className="d-none headerDisplay">STANDARD</b>
            {data.secondData.map((item)=> <li key={item}>{item}</li>)}
            </ul>
          </div>
          <div className="col-md-4">
            <ul className="mt-3">
            <b className="d-none headerDisplay">PREMIUM</b>
            {data.thirdData.map((item)=> <li key={item}>{item}</li>)}
            </ul>
          </div>
        </div>
      </div>)
    })
  }
  return (
    <>
      <h1 className="text-center">Our Pricing Packages</h1>
      <div className="container mb-5 p-md-20">
        <div className="row flex">
          <div className="col-md-4 pricing-container">
            <img alt="Basic" src="./Basic.jpg"  className="img-fluid" style={{"aspectRatio":"1"}} />
            <h3 className="text-center mt-2">Basic</h3>
            {/* <p className="text-center">
              price may vary depending on no. of floors
            </p> */}
            <div className="price-bar">
              <span>₹ 1999</span>
              <sup>₹</sup>1850<sub>/ sft.</sub>
            </div>
            {/* <ul className="text-center mt-3">
              <li><img alt="list-icon" src={icon}/>RCC framed structure</li>
              <li><img alt="list-icon" src={icon}/>Basic materials</li>
              <li><img alt="list-icon" src={icon}/>RCC foundation & roof</li>
            </ul> */}
          </div>
          <div className="col-md-4  pricing-container">
            <img src="./standard.jpg" alt="standard" className="img-fluid" style={{"aspectRatio":"1"}} />
            <h3 className="text-center mt-2">Standard</h3>
            {/* <p className="text-center">
              price may vary depending on no. of floors
            </p> */}
            <div className="price-bar">
              <span>₹ 2199</span>
              <sup>₹</sup>1999<sub>/ sft.</sub>
            </div>
            {/* <ul className="text-center  mt-3">
              <li><img alt="list-icon" src={icon}/>All features in basic package</li>
              <li><img alt="list-icon" src={icon}/>Free Smart Home Automation</li>
              <li><img alt="list-icon" src={icon}/>Free Organic Terrace Gardening</li>
            </ul> */}
          </div>
          <div className="col-md-4  pricing-container">
            <img src='./Premium1.jpeg' alt="Premium" className="img-fluid" style={{"aspectRatio":"1"}} />
            <h3 className="text-center mt-2">Premium</h3>
            {/* <p className="text-center">
              price may vary depending on no. of floors
            </p> */}
            <div className="price-bar">
              <span>₹ 2499</span>
              <sup>₹</sup>2299<sub>/ sft.</sub>
            </div>
            {/* <ul className="text-center  mt-3">
              <li><img alt="list-icon" src={icon}/>All features in standard package</li>
              <li><img alt="list-icon" src={icon}/>Granite flooring</li>
              <li><img alt="list-icon" src={icon}/>Premium quality materials</li>
            </ul> */}
          </div>
        </div>
      </div>
      <div className="container mb-5">
        <div className="row">
          <div className="col-12">
          {mappingItems(pricingContent)}
            <p className="price-desc">
              Check out our free construction cost calculator tool below. This
              tool shows you the construction cost in Chennai. Just choose the
              package, type in the construction area and few other basic info
              and get the construction cost in Chennai. If you want to know the
              construction cost in Chennai 2021, you can know it by entering the
              details in the construction cost calculator.
            </p>
          </div>
          <div className="col-12">
            <p className="price-desc">
              Note: Price may vary depending on number of floors.
            </p>
          </div>
        </div>
      </div>
      <CostCalculator />
      <CustomerReview />
    </>
  );
}

export default Pricing;
