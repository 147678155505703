import React from "react";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import {useState} from "react";

function Navbar() {
  return (
    <nav
      className="navbar navbar-expand-lg  navbar-light"
    >
      <div className="container-fluid">
        <Link to="/aboutUs">
            <img
              src="./PNG.png"
              style={{ width:"80px",aspectRatio: "1" }}
              className="d-inline-block align-text-top img-fluid ms-4 mt-2"
            ></img>
          </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse smallScreenRes" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <Link className="navbar-brand" to="aboutUs">
               Home &nbsp;
              </Link>
            </li>
            <li className="nav-item">
              <Link className="navbar-brand" to="ourServices">
                Services &nbsp;
              </Link>
            </li>
            <li className="nav-item">
              <Link className="navbar-brand" to="projects">
                Projects &nbsp;
              </Link>
            </li>
            <li className="nav-item">
              <Link className="navbar-brand" to="knowledge">
                Knowledge &nbsp;
              </Link>
            </li>
            <li className="nav-item">
              <Link className="navbar-brand" to="Pricing">
                Pricing &nbsp;
              </Link>
            </li>
            <li className="nav-item">
              <Link className="navbar-brand" to="contactUs">
                Contact Us &nbsp;
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;



//--------bootstrap Navbar -------
// <li className="nav-item dropdown">
// <Link
// className="nav-link dropdown-toggle"
// href="#"
// id="navbarDropdown"
// role="button"
// data-bs-toggle="dropdown"
// aria-expanded="false"
// >
// Knowledge &nbsp;
// </Link>
// <ul className="dropdown-menu" aria-labelledby="navbarDropdown">
// <li>
//   <Link className="dropdown-item" href="#">
//     Action
//   </Link>
// </li>
// <li>
//   <Link className="dropdown-item" href="#">
//     Another action
//   </Link>
// </li>
// <li>
//   <hr className="dropdown-divider" />
// </li>
// <li>
//   <Link className="dropdown-item" href="#">
//     Something else here
//   </Link>
// </li>
// </ul>
// </li>
