import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from "./components/Navbar";
import About from "./components/AboutUs";
import Project from "./components/Project/Project.jsx";
import OurServices from "./components/Services/OurServices";
import Knowledge from "./components/Knowledge";
import ContactUs from "./components/ContactUs";
import Footer from "./components/Footer";
import Home from "./components/Home";
import Pricing from "./components/Pricing/Pricing"
import Calculator from "./common components/Calculator/Calculator"

function App() {
  return (
    <>
      <Router>
        <Navbar />
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="/aboutUs" element={<Home />} />
          <Route path="/ourServices" element={<OurServices />} />
          <Route path="/projects" element={<Project />} />
          <Route path="/knowledge" element={<Knowledge />} />
          <Route path="/Pricing" element={<Pricing />} />
          <Route path="/contactUs" element={<ContactUs />} />
          <Route path="/calculator" element={<Calculator />} />
          <Route path="*" element={<Home />} />
        </Routes>
        <Footer />
      </Router>
    </>
  );
}

export default App;
