import React from "react";
import { homepage } from "../Database/metaData";
import { homepageImages } from "../Database/metaData";
import "./Home.css";
import Carosel from "../common components/Carosel";

function Home() {
  let quote = homepage.homePageQuote;
  let author = homepage.homePageQuoteAuthor;
  let introData = homepage.data;
  console.log("images",homepageImages)
  return (
    <>
      <div className="container-fluid">
          <div className="row mt-5 mb-5">
            {/* <div className="col col-lg-4 col-md-5 col-xs-5 quote mt-5 ms-5 me-4 h2-3 mb-5 ">
              <h4>{quote}</h4>
              <h3 style={{ textAlign: "end" }}>{author}</h3>
            </div> */}

            {/* <div className="col-lg-8 offset-2">
            <Carosel/>
            </div> */}
            <div className="col-lg-6 offset-1 col-md-6 order-md-1 order-2" >
              <h1 className="heading"> One Stop Solution For</h1>
              <h1 className="heading"> Your Construction !</h1>
              <p className="pb-4 pr-md-5 lead">
              100+ clients with Premium Solutions Services.
              </p>
            </div>
            <div className="col-lg-5 col-md-6 mb-4 mb-md-0 order-md-2 order-1">
              {/* <img src="./PNG-2.png" className="img-fluid" alt="" /> */}
              <Carosel/>
            </div>
          </div>
          {/*add 5 images to the bottom of this row */}
          <div className="row mt-5 mb-5">
            <div className="col-md-8 col-xs-12 offset-1 mt-3">
                <h3 className="mb-3">Your Dream House Planning Process</h3>
                <div className="d-flex gap-5 ms-5 wrapping">
                  {homepageImages.images.map((items, index)=>{
                  return  (<div><img key={index} style={{"height":"10rem","aspectRatio":"1"}} src={items.img? items.img : "./logo.jpg"} alt="img"/><p style={{"paddingTop":"10px","paddingLeft":"10px","maxWidth":"10rem"}}>{items.cont}</p></div>)
                  })}
                </div>

            </div>
          </div>
        {/* <div className=" row intro-data col-lg-8 col-md-9 col-xs-10 offset-1 my-4 p-5">
          <h2>{introData}</h2>
        </div> */}
      </div>
    </>
  );
}

export default Home;
