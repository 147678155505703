import React,{useState,useEffect} from 'react'

function Calculator() {
  const [emi,setEmi] = useState("");
  const [details,setDetails] = useState({principalAmount:"",roi:"",lt:""});
  const [interest, setInterest] = useState('');

      useEffect(()=>{
        let rateOfInterest =  (details.roi)/12/100;
        let months = details.lt*12;
        let formul = Math.pow(1+rateOfInterest,months);
        let emi = (details.principalAmount)*rateOfInterest*formul/(formul-1)
        setEmi(numberWithCommas(emi.toFixed(2)));
        let interest = ( emi * months ) - details.principalAmount;
        setInterest(numberWithCommas(interest.toFixed(2)));
      },[details])


      let valueUdpateEMIcalculate = (e)=>{
        const target = e.target;
        setDetails({
          ...details,
          [target.id]: target.value
        });
      }

      let numberWithCommas = (x)=> {
        return x.toString().split('.')[0].length > 3 ? x.toString().substring(0,x.toString().split('.')[0].length-3).replace(/\B(?=(\d{2})+(?!\d))/g, ",") + "," + x.toString().substring(x.toString().split('.')[0].length-3): x.toString();
      }

      return (
        <>
          <div className='container '>
              <h1>EMI Calculator</h1>
              <form className='row col-md-5 border p-4'>
                  <div className="form-group">
                      <label htmlFor="principalAmount">Loan Amount</label>
                      <input type="number" value={details.principalAmount} className="form-control" onChange={(e)=>valueUdpateEMIcalculate(e)} id="principalAmount" placeholder="Enter Loan Required"/>
                  </div>
                  <div className="form-group mt-3">
                      <label htmlFor="roi">Rate of Interest</label>
                      <input type="number" className="form-control" onChange={(e)=>valueUdpateEMIcalculate(e)} value={details.roi} id="roi" placeholder="Enter Rate of Interest"/>
                  </div>
                  <div className="form-group mt-3">
                      <label htmlFor="Lt">Loan Tenure</label>
                      <input type="number" className="form-control" id="lt" onChange={(e)=>valueUdpateEMIcalculate(e)} value={details.lt} placeholder="Enter no of years"/>
                  </div>
              </form>
              { emi&&details.principalAmount&&details.roi&&details.lt ? <h3 className='mt-3'>EMI amount is {emi}</h3> : <h3 className='mt-3'>Enter all details for EMI !!</h3>}
              { emi&&details.principalAmount&&details.roi&&details.lt ? <h3 className='mt-3'>Interest amount is {interest}</h3> : ""}

          </div>
        </>
    )
}
export default Calculator
