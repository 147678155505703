import React from "react";
import './contactUs.css'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPhone, faEnvelope, faMap  } from '@fortawesome/free-solid-svg-icons'

function ContactUs() {
  return (
    <>
    <div className="container mb-5">
    <div className="row">
      <div className="col-md-12 text-center">
        <h1 className="page-title">Get Ahold of Us !!</h1>
      </div>
    </div>
  </div>
    <div id="rs-contact" className=" ">
    <div className="container rs-contact sec-spacer">
      <div className="contact-bg">
        <div className="row">
          <div className="col-md-4 col-sm-4">
            <div className="contact-address">
              <div className="address-item">
                <div className="address-icon">
                <FontAwesomeIcon icon={faPhone} />
                </div>
                <div className="address-text">
                  +91 7010491576
                  <br/>
                  +91 9965268003
                </div>
              </div>
              <div className="address-item">
                <div className="address-icon">
                  <FontAwesomeIcon icon={faEnvelope} style={{width:"40px"}} />
                </div>
                <div className="address-text">
                  sparrownestconstructions@gmail.com
                  <br/>
                  <a href="https://sparrownestconstructionsolutions.com">sparrownestconstructionsolutions.com</a>
                </div>
              </div>
              <div className="address-item">
                <div className="address-icon">
                <FontAwesomeIcon icon={faMap}  style={{width:"40px"}} />
                </div>
                <div className="address-text">
                704/603, Balaram Complex,Near Hanuman temple
                  KCC Nagar Main Road, Hosur, TamilNadu 635109
                </div>
              </div>
            </div>
          </div>

          <div className="col-md-8 col-sm-8 m-auto pad" style={{paddingLeft:"9rem"}}>
            <div className="contact-form" style={{marginBottom: "2rem"}}>
              <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3891.422363414542!2d77.82743187436063!3d12.75106028754446!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3b04f112b2f1f935%3A0x2fab1c9ae60f3f7d!2sSPARROW%20NEST%20CONSTRUCTION%20SOLUTIONS!5e0!3m2!1sen!2sin!4v1695120581682!5m2!1sen!2sin" width="600" height="450" style={{"border":"0"}} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</>
  );
}

export default ContactUs;

// <h1>Contact Us</h1>
// <div className="container border">
//   <form className="ms-3 mt-4 g-3 needs-validation row col-md-5 " novalidate>
//     <div className="row col-md-4">
//       <label for="validationCustom01" className="form-label">
//         First name
//       </label>
//       <input
//         type="text"
//         className="form-control"
//         id="validationCustom01"
//         value="Mark"
//         required
//       />
//       <div className="valid-feedback">Looks good!</div>
//     </div>
//     <div className="row col-md-4">
//       <label for="validationCustom02" className="form-label">
//         Last name
//       </label>
//       <input
//         type="text"
//         className="form-control"
//         id="validationCustom02"
//         value="Otto"
//         required
//       />
//       <div className="valid-feedback">Looks good!</div>
//     </div>
//     <div className="row col-md-4">
//       <label for="validationCustom03" className="form-label">
//         City
//       </label>
//       <input
//         type="text"
//         className="form-control"
//         id="validationCustom03"
//         required
//       />
//       <div className="invalid-feedback">Please provide a valid city.</div>
//     </div>
//     <div className="row col-md-4">
//       <label for="validationCustom04" className="form-label">
//         State
//       </label>
//       <input
//         type="text"
//         className="form-control"
//         id="validationCustom04"
//         required
//       />
//       <div className="invalid-feedback">Please select a valid state.</div>
//     </div>
//     <div className="row col-md-3">
//       <label for="validationCustom05" className="form-label">
//         Zip
//       </label>
//       <input
//         type="text"
//         className="form-control"
//         id="validationCustom05"
//         required
//       />
//     </div>
//     <div className="">
//       <button className="btn btn-primary" type="submit">
//         Submit form
//       </button>
//     </div>
//   </form>
// </div>
