import React from 'react'
import './Footer.css'
import { Link } from "react-router-dom";

function Footer() {
    return (
        <div className="footer">
          <div className="container">
          <footer className="d-flex flex-wrap justify-content-between align-items-center">
              <p className="col-md-4 mb-0 text-white">© Sparrow Nest construction solutions</p>

              <a href="/" className="col-md-4 d-flex align-items-center justify-content-center mb-3 mb-md-0 me-md-auto link-dark text-decoration-none">
              <svg className="bi me-2" width="40" height="32"></svg>
              </a>
              <ul className="nav col-md-4 justify-content-end">
                <li className="nav-item"><Link to="aboutUs" className="nav-link px-2 text-white">Home</Link></li>
                <li className="nav-item"><Link to="ourServices" className="nav-link px-2 text-white">Features</Link></li>
                <li className="nav-item"><Link to="Pricing" className="nav-link px-2 text-white">Pricing</Link></li>
                <li className="nav-item"><Link to="knowledge" className="nav-link px-2 text-white">FAQs</Link></li>
                <li className="nav-item"><Link to="aboutUs" className="nav-link px-2 text-white">About</Link></li>
              </ul>
          </footer>
          </div>
        </div>
    )
}

export default Footer
