export const projects = {
  Completed: [
    {
      name: "Mr L.Vijayanathan Residential Project",
      place: "Hosur",
      img: "./Mr.vijayanathan.jpeg"
    },
    {
      name: "Mr.Arun Prasath R and Pradeepa V residential project",
      place: "Nagercoil",
      img: "./Mr.ArunPrashanth.jpg",
    },
    {
      name: "Mr.Aravindh v Residential project",
      place: "Hosur",
    },
    {
      name: "Mr.Bharanidharan B  project cost effective car parking project",
      place: "Hosur",
      img: "./Mr.Bharani.jpg",
    },
    {
      name: "Mr.Arun Pragatheesh R and Priya K residential project",
      place: "Nagercoil",
      img: "./Mr.Arun.jpg",
    },
    {
      name: "Mr Deepak S Building structural strengthening project Whitefield",
      place: "Bangalore",
      img: "./Mr.Deepak.jpg",
    },
    {
      name: "Sri Siddhi Vinayaka temple waterproofing project",
      place: "Bagalur",
      img: "./vinayakaTemple.jpg",
    },
    {
      name: "semzy Automotive and carriage product pvt.Ltd consulting work",
      place: "Nagercoil",
    },
  ],
  Ongoing: [
    {
      name: "Mr. B.Prathap Residential Apartment project",
      place: "Hosur",
      img: "./Mr.Prathap.jpg",
    },
    {
      name: "Dr.Mohideen Anees Residential project (Consulting)",
      place: "Virudunagar",
      img: "./Dr.A.Mohideen.jpg",
    },
  ],
};

export const services = {
  services: [
    {
      title: "Turn key building construction projects",
      summary: "",
    },
    {
      title: "Repair and rehabilitation of building structures",
      summary: "",
    },
    {
      title:
        "Vernacular/Traditional building construction (Mud block,Earth wall etc)",
      summary: "",
    },
    {
      title: "Civil engineering project consultants",
      summary: "",
    },
    {
      title: "Architectural planning",
      summary: "",
    },
    {
      title: "Structural consultants",
      summary: "",
    },
  ],
};

export const knowledge = {
  knowledge: [
    {
      title: "Vastu",
      summary: `Vastu shastra is an Ancient knowledge of using five natural elements (Land, Water,,wind, space and Fire) favour to his living space and surroundings.Most of the vastu rules resembles now a days Building design principles(In vastu rules, Pillars/Column must be in even numbers.In design principles it's says symmetry/balanced).
    Manayadi Shastra is a part of Vastu which mostly followed in South Indian building constructions. this says about rules of site's lenth, breadth & dimensions.`,
      img: "vasthu.jpg",
    },
    {
      title: "Green buildings",
      summary: `In the 21st century, we all wish to lead an organic life here. Expecting a pollution-free and clear environment and Green building construction brings this notion to reality in our home.
            At every stage, we follow certain practices to accomplish these green initiatives.
            The Green building construction process begins from the Design stage to the life cycle completion of buildings.

            *Designing stage, we bring Heat, Air, Ventilation, and Cooling (HAVC)to the home to take these energies from nature maximum through appropriate design strategies.
            *Most of the materials used for construction contain low embodied energies.
            *control waste and overuse of materials
            *Use solar power
            *Use high-star-rated electrical equipment.
    `,
      img: "GREEN BUILDING.jpg",
    },
    {
      title: "Sustainable Buildings",
      summary: "Sustainable building is a building that, because of its construction and features, can maintain or improve the quality of life of the environment in which it is located. To do this, it is essential to achieve a high level of efficiency: reducing the consumption of energy, water and other resources minimises pollution. There many institutions like GRIHA ,NGBC in India and many international instution sugest the guidelines to follow. After completion of projects they provide Rating for that particular projects.",
      img: "./SustainableBUILDINGS.jpg",
    },
    {
      title: "Why should we go for a Green rating materials?",
      summary: `Green building materials are composed of renewable, rather than nonrenewable resources. Green materials are environmentally responsible because impacts are considered over the life of the product.
      Overall material/product selection criteria:
      Resource efficiency,
      Indoor air quality, 
      Energy efficiency,
      Water conservation,
      Affordability`,
      img: "",
    },
    {
      title: "Why need set back in buildings?",
      summary: "The meaning of setback is the minimum open space required around any building or structure. Municipal regulations of a State provide a specific distance that should be maintained between a building and the boundary of the plot on which the building is being constructed. The set back distance is necessary to ensure so that the structure stays away from roads, water bodies or other buildings. Setback area in buildings are required at their front, rear and sides, and the specifications vary from one area to another",
      img: "./setBack.png",
    },
    {
      title: "What is operation cost in buildings?",
      summary: `Is it useful for residential buildings?

      Building operating costs are the expenses related to the operation and management of the buildings.
      Early days term used in commercial space rental agreements in which the costs to maintain tax expenses, building maintenance, janitorial services, utilities(electrical, plumbing, sewage disposal, etc) securities, maintenance supplies, future expenses, property management charges, etc.

      Nowadays the concept of group housing(Apartment)  must calculate the operating cost to maintain the building in the same condition.`,
      img: "./opertaingCost.jpg",
    },
  ],
};

export const contactUs = {
  number: ["9965268003", "7010491576"],
  address: `Sparrow Nest Construction solutions
    14/2, Rajendra Gandhi compounds, Pazhavilai and post,K.K.Dist
    635109`,
};
export const homepageImages = {
  images: [
    {
      img: "./clientMeeting.jpg",
      cont: "Client Meeting",
    },
    {
      img: "./planning.webp",
      cont: "Plan Execution",
    },
    {
      img: "./projectexecutionandConstruction.webp",
      cont: "Construction",
    },
    {
      img: "./QualityControl.jpg",
      cont: "Quality check",
    },
    {
      img: "./HandOver.JPG",
      cont: "Handovering your dream house",
    },
  ],
};
export const homepage = {
  data: `sparrow Nest Construction solutions,we are since 2017 in the field of construction practice.
    More than 10 projects we have handled in Hosur,
    Banglore and Nagercoil. Sparrow Nest solutions
    we are expert in the field of construction.
    Constructed by Sparrow Nest Construction solutions
    is an prestigious. Our clients fully satisfied
    about our services.we give importance to Vastu,
    Climatology & Green practices`,
  homePageQuote:
    "You can dream, create, design, and build the most wonderful place in the world. But it requires people to make the dream a reality.",
  homePageQuoteAuthor: "- Walt Disney",
  keypoints: {
    title: "Our Exclusiveness :",
    points: [
      "No compermise in quality",
      "ISI materials used",
      "300+ quality checks",
      "Zero delay",
      "project Managed by Project management tools",
      "Camera monitoring",
      "Trained Labourers used",
    ],
  },
};
export const pricingContent = [
  {
    headName: "DESIGN",
    firstData: ["2D Floor Plan"],
    secondData: ["2D & 3D Elevation"],
    thirdData: ["Floor Plan,3D Elevation,Structural Detailing"],
  },
  {
    headName: "STRUCTURE",
    firstData: [
      "Basement: up to 2feet height from ground level",
      `Cement: Priya/Sager/Maha`,
      `Wall Size :Brick wall 9inches for external walls and 4.5inches for internal partition walls, 4inches solid blockfor parapet wall`,
      `Sand: M-sand and P-sand`,
      `Aggregate: 20mm & 40mm`,
      `Concrete Mix: M20`,
      `Celling height: 10feet`,
    ],
    secondData: [
      "Basement Up to 2feet height from ground level",
      `Wall Size :Brick wall 9inches for external walls and 4.5inches for internal partition walls, 6inches solid block for parapet wall`,
      `Cement: Priya/Sager/Maha marked(43grade)`,
      `Sand: M-sand and P-sand`,
      `Aggregate: 20mm & 40mm`,
      `Concrete Mix: M20`,
      `Celling height: 10feet`,
    ],
    thirdData: [
      "Basement Up to 3feet height from ground level",
      `Wall Size: Brick wall 9inches for external walls and 4.5inches for internal partition walls.`,
      `Cement: Ultratech/Dalmia/Ramco/Sankar`,
      `Sand: M-sand and P-sand`,
      `Aggregate: 20mm & 40mm`,
      `Concrete Mix: M20`,
      `Celling height: 10feet`,
    ],
  },
  {
    headName: "KITCHEN",
    firstData: [
      `Wall tile: 2feet above the kitchen slab ₹35/sq ft`,
      `Main sink faucet: ₹1500 / any ISI marked`,
      `Kitchen sink: Stainless steel up to ₹1500/-`,
      `Kitchen granite slab: 20mm thk up to ₹80/sq ft`,
    ],
    secondData: [
      `Wall tile: 2feet above the kitchen slab ₹45/sq ft`,
      `Main sink faucet: ₹2000 CERA/ESSCO/Parryware basic model`,
      `Kitchen sink: Stainless steel up to ₹2000/-`,
      `Kitchen granite slab: 20mm thk up to ₹100/sq ft`,
    ],
    thirdData: [
      `Wall tile: 2feet above the kitchen slab ₹55/sq ft`,
      `Main sink faucet: ₹3000 JAGUAR BASIC MODEL`,
      `Kitchen sink: Stainless steel up to ₹3000/-`,
      `Kitchen granite slab: 20mm thk up to ₹120/sq ft`,
    ],
  },
  {
    headName: "TOILET",
    firstData: [
      `Wall tile: For 7feet up to ₹30/sq ft`,
      `Sanitaryware & CP fittings: ₹10000`,
      `CPVC/PVC: Any ISI marked`,
      `Accessories: EWC, Health faucet, Wash basin for Master bed Toilet(ONLY) 2in 1 wall mixer, over head shower`,
      `Colour: White`,
      `Door: PVC`,
    ],
    secondData: [
      `Wall tile: For 7feet up to ₹35/sq ft`,
      `Sanitaryware & CP fittings: ₹13000`,
      `CPVC/PVC: Supreme/Ashirwad/Astral`,
      `Accessories: EWC, Health faucet, Wash basin for Master bed Toilet 2in 1 wall mixer, over head shower basic Parry ware`,
      `Colour: White`,
      `Door: PVC`,
    ],
    thirdData: [
      `Wall tile: For 7feet up to ₹40/sq ft`,
      `Sanitaryware & CP fittings: ₹15000`,
      `CPVC/PVC: Supreme/Ashirwad/Astral`,
      `Accessories: EWC, Health faucet, Washbasin for Master bed Toilet 3in 1 wall mixer, over head shower Parry ware/basic Jaguar`,
      `Colour: White`,
      `Door: PVC Mat finished Door`,
    ],
  },
  {
    headName: "PLUMBING",
    firstData: [
      `Bath room: 1 wash basintap,1shower head with wall mixer, Floor mount EWC with health faucet`,
      `Dining: 1 Wash basin (Point Only)`,
      `Kitchen: Single stainless steel sink with tap & RO Point`,
      `Utility: washing machine inlet point ONLY`,
    ],
    secondData: [
      `Bath room: 1 wash basin tap,1 shower head with wall mixer, Floor mount EWC with health faucet`,
      `Dining: 1 Wash basin with tap`,
      `Kitchen: Single stainless steel sink with tap & RO Point`,
      `Utility: 1 tap for washing machine 1point for service`,
    ],
    thirdData: [
      `Bath room: 1 wash basin tap,1shower head with wall mixer, Floor mount EWC with health faucet`,
      `Dining: 1 Wash basin with tap`,
      `Kitchen: Double size stainless steel sink with tap & RO Point`,
      `Utility: 1 tap for washing machine 1point for service`,
    ],
  },
  {
    headName: "DOORS AND WINDOW",
    firstData: [
      `Main door:3’3” * 7’0” 5” * 3” Country wood Door(Plain door)`,
      `Internal Doors: Sal/country wood frame 3’0” * 7’0” ht Flush Door (Plain Door)`,
      `Window: Aluminum 2track`,
      `Toilet Door: 2’6” * 7’0” PVC`,
    ],
    secondData: [
      `Main door:3’3” * 7’0” 5” * 3” teak wood Teak Door(standard design)`,
      `Internal Doors: Sal/country wood frame 3’0” * 7’0” ht Flush Door`,
      `Window: 2 Track Aluminum powder coated/UPVC`,
      `Toilet Door: 2’6” * 7’0” PVC`,
    ],
    thirdData: [
      `Main door:3’3”* 7’0” 5” * 3” teak wood Teak Door(Customized design)`,
      `Internal Doors: Sal/country wood frame 3’0” * 7’0” ht Flush Door(DESIGNED)`,
      `Window: 2 Track Aluminum powder coated/UPVC 3track with mosquito net`,
      `Toilet Door: 2’6” * 7’0” PVC`,
    ],
  },
  {
    headName: "PAINTING",
    firstData: [
      `Interior:1 coat of putty with ISI marked, 1Coat primer, 2coat of Nippon, Emulsion paint, Ceiling white`,
      `Exterior: 1Coat primer, 2coat of Nippon Exterior Emulsion paint`,
    ],
    secondData: [
      `Interior:2 coat of Nippon silky putty, 1Coat primer, 2coat of Nippon, Emulsion paint, Ceiling white`,
      `Exterior: 1Coat primer, 1coat of NipponSilky putty only for elevation 2coat of Nippon Exterior Emulsion paint`,
    ],
    thirdData: [
      `Interior:2 coat of Nippon FR putty, 1Coat Nippon primer, 2coat of Nippon, Emulsion paint, Ceiling white`,
      `Exterior: 1Coat primer, 1coat of Nippon FR putty only for elevation 2coat of Nippon Exterior Emulsion paint`,
    ],
  },
  {
    headName: "FLOORING",
    firstData: [
      `Living, Dinning & kitchen: Tile up to ₹45/sq ft`,
      `Balcony & Utility: ₹35/sq ft`,
      `Staircase Flooring: Antiskid tile up to ₹25/sq ft`,
      `Parking: Tile ₹25/sq ft`,
    ],
    secondData: [
      `Living, Dinning & kitchen: Tile up to ₹55/sq ft`,
      `Balcony & Utility: ₹45/sq ft`,
      `Staircase Flooring: Antiskid tile up to ₹30/sq ft`,
      `Parking: Tile ₹30/sq ft`,
    ],
    thirdData: [
      `Living, Dinning & kitchen: Tile/Granite up to ₹80/sq ft`,
      `Balcony & Utility: ₹55/sq ft`,
      `Staircase Flooring: Antiskid tile up to ₹45/sq ft`,
      `Parking: Tile ₹45/sq ft`,
    ],
  },
  {
    headName: "ELECTRICAL",
    firstData: [
      `Wires – KUNDHAN or any ISI brand`,
      `Switches – HIFI`,
      `Electrical Points:`,
      `Bedroom – 1 switch boxwith switches for 1 fan, 2 lights and 1 five amps socket with a switch near the door and another switch box with the same set of switches near the bed for two-way control.1 AC point, 1 fan point and 2 lights point`,
      `Bathroom- 1 switch box
      with 1 switch for light and 1
      switch for heater near the
      door & 15 amps socket for
      heater.`,
      `Hall- 1 switch box near the
      door for 2 fans, 2 lights & 1
      five amps socket and
      another switch box with
      same set of switches near
      sofa. 1 switch box with 1 five
      amps sockets and
      switches along with cable
      point for TV connection`,
      `Dining-1 switch box with
      switches for 1 fan & 1 light`,
      `Pooja- 1 switch box with
      switch for 1 light`,
      `Kitchen- 1 switch box with
      switch for 2 lights, 1 exhaust
      fan. 1 switch box with 15
      amps socket & switch for
      refrigerator. 1 switch box
      with one 15 amps socket &
      switch for mixie/ oven.`,
      `Service-1 switch for light, 1
      switch box with 15 amps
      socket & switch for
      washing machine`,
    ],
    secondData: [
      `Wires – VGAURD/KUNDHAN`,
      `Switches – Anchor Roma/GM`,
      `Electrical Points:`,
      `Bedroom – 1 switch box with switches for 1 fan, 2
      lights and 1 five amps
      socket with switch near
      door and another switch
      box with same set of
      switches near bed for two-
      way control.
      1 AC point, 1 fan point and 2
lights point`,
      `Bathroom- 1 switch box
      with 1 switch for light and 1
      switch for heater near the
      door & 15 amps socket for
      heater. 1 switch box with 1
      five amps socket for hair
      dryer near the mirror`,
      `Hall- 1 switch box near the
      door for 2 fans, 2 lights & 1
      five amps socket and
      another switch box with
      same set of switches near
      sofa. 1 switch box with 2 five
      amps sockets and
      switches along with cable
      point for TV connection`,
      `Dining-1 switch box with
      switches for 1 fan & 1 light`,
      `Pooja- 1 switch box with
      switch for 1 light`,
      `Kitchen- 1 switch box with
      switch for 2 lights, 1 exhaust
      fan. 1 switch box with 15
      amps socket & switch for
      refrigerator. 1 switch box
      with two 15 amps socket &
      switch for mixie/ oven.`,
      `Service- 1 switch for light, 1
      switch box with 15 amps
      socket & switch for
      washing machine`,
      `Extras – One 5 amps
      switch & socket for
      computer`,
    ],
    thirdData: [
      `Wires – VGAURD/Finolex`,
      `Switches – Legrand`,
      `Electrical Points:`,
      `Bedroom – 1 switch box
      with switches for 1 fan, 2
      lights and 1 five amps
      socket with switch near
      door and another switch
      box with same set of
      switches near bed for two-
      way control.
      1 AC point, 1 fan point and 2
lights point`,
      `Bathroom- 1 switch box
      with 1 switch for light and 1
      switch for heater near the
      door & 15 amps socket for
      heater. 1 switch box with 1
      five amps socket for hair
      dryer near the mirror`,
      `Hall- 1 switch box near the
      door for 2 fans, 2 lights & 1
      five amps socket and
      another switch box with
      same set of switches near
      sofa. 1 switch box with 3 five
      amps sockets and
      switches along with cable
      point for TV connection`,
      `Dining-1 switch box with
      switches for 1 fan & 1 light`,
      `Pooja- 1 switch box with
      switch for 1 light`,
      `Kitchen- 1 switch box with
      switch for 2 lights, 1 exhaust
      fan. 1 switch point for chimney. 1 switch box with
      15 amps socket & switch for
      refrigerator. 1 switch box
      with three 15 amps socket
      & switch for mixie/ oven.`,
      `Service- 1 switch for light, 1
      switch box with two 15
      amps socket & switch for
      washing machine (one in
      the top & on in the bottom)`,
      `Extras– 1 additional switch
      & socket in each room for
      mobile charging. 5 amps
      socket & switch for
      computer`,
    ],
  },
  {
    headName: "OTHER INCLUSIVES",
    firstData: [
      `Parapet wall up to 3 feet
      high (included if headroom
      is built)`,
    ],
    secondData: [
      `Overhead Tank: 1000 litres
      Sintex`,
      `MS Staircase Railing`,
      `Parapet Wall 3 Feet High
      (included if headroom is
      built)`,
      `Roof weathering is
      included if the build-up
      area is more than 2000 sq.Ft.`,
      `Rainwater harvesting`,
    ],
    thirdData: [
      `Overhead Tank: 1000 litres
      Sintex`,
      `SS Staircase Railing`,
      `Parapet Wall 3 Feet High
      (included if headroom is
      built)`,
      `Roof weathering is
      included if the build-up
      area is more than 2000 sq.
      Ft.`,
      `1 MS safety grill gate for the
      main door`,
      `Rainwater harvesting`,
    ],
  },
  {
    headName: "LOFTS & SHELVES",
    firstData: [
      `1 Loft in each bedroom,
      kitchen & pooja room on
      the shorter side of the wall`,
      `1 Shelf in each bedroom,
      kitchen & pooja room of
      maximum width 5 feet`,
    ],
    secondData: [
      `1 Loft in each bedroom,
      kitchen & pooja room on
      the shorter side of the wall`,
      `1 Shelf in each bedroom,
      kitchen & pooja room of
      maximum width 5 feet`,
    ],
    thirdData: [
      `1 Loft in each bedroom,
      kitchen & pooja room on
      the shorter side of the wall`,
      `1 Shelf in each bedroom,
      kitchen & pooja room of
      maximum width 5 feet`,
    ],
  },
  {
    headName: "EXTRA CHARGES",
    firstData: [
      `Compound Wall`,
      `Lift`,
      `Carpentry & other wooden
      work`,
      `EB Connections & Charges`,
      `Govt. approval charges`,
      `Water Connections &
      Charges`,
      `Underground water
      storage Sump`,
      `Septic Tank`,
      `Overhead sintex tank `,
      `Overhead concrete tank`,
      `Elevation Work Extra`,
      `Water recycling tank
      based on the requirement`,
      `Additional foundation
      height`,
      `Structural designing`,
      `3D elevation designing`,
      `Rainwater harvesting`,
      `Outer area development
      (setback)`,
      `Roof weathering`,
    ],
    secondData: [
      `Compound Wall`,
      `Lift`,
      `Carpentry & other wooden
      work`,
      `EB Connections & Charges`,
      `Govt. approval charges`,
      `Water Connections &
      Charges`,
      `Underground water
      storage Sump `,
      `Septic Tank`,
      `Overhead concrete tank `,
      `Elevation Work Extra`,
      `Water recycling tank
      based on the requirement`,
      `Additional foundation
      height`,
      `Interior 3D view or
      walkthrough`,
      `Outer area development
      (setback)`,
      `Roof weathering`,
      `Gates`,
      `Electrical/plumbing
      drawings`,
    ],
    thirdData: [
      `Compound Wall`,
      `Lift`,
      `Carpentry & other wooden
      work`,
      `EB Connections & Charges`,
      `Govt. approval charges`,
      `Water Connections &
      Charges`,
      `Underground water
      storage Sump `,
      `Septic Tank`,
      `Overhead concrete tank`,
      `Elevation Work Extra`,
      `Water recycling tank
      based on the requirement`,
      `Additional foundation
      height`,
      `Interior 3D view or
      walkthrough`,
      `Outer area development
      (setback)`,
      `Roof weathering`,
      `Gates`,
      `Electrical/plumbing
      drawings`,
    ],
  },
];
