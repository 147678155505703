import React, { useEffect, useState, useRef } from 'react' 
import "./carosel.css"

function Carosel() {
  const [img,setImg] = useState("./Mr.Prathap.jpg");
  const imgArray = ["./Mr.Prathap.jpg","./Mr.ArunPrashanth.jpg","./Mr.Deepak.jpg","./Dr.A.Mohideen.jpg","./vinayakaTemple.jpg","./Mr.Arun.jpg"]
  const interval = useRef();
  
  function carsoelCall(){
    let val = 0;
    interval.current = setInterval(()=>{
    // console.log(val)
      if(val == 5)val=-1;
      val = val+ 1;
      setImg(imgArray[val])
    },3000)
  }

  useEffect(()=>{
   carsoelCall();
    return () => clearInterval(interval.current);
  },[])


  function left (){
    clearInterval(interval.current);
    let x = imgArray.indexOf(img)
    if(x == 0){
      x = imgArray.length
    }
    setImg(imgArray[x-1])
    setTimeout(()=>{
      clearInterval(interval.current);
      carsoelCall();
    },5000)
  }
  function right (){
    clearInterval(interval.current);
    let x = imgArray.indexOf(img)
    if(x == imgArray.length-1){
      x = -1
    }
    setImg(imgArray[x+1])
    setTimeout(()=>{
      clearInterval(interval.current);
      carsoelCall();
    },5000)
  }

  return (
    <>
    <div className='position-relative'>
    <img src="./left_arrow.png" className='pointer position-absolute fromTop'  onClick={left}/>
    <img src={img} alt="" style={{"width":"25rem","height":"16rem"}}/>
    <img src="./right_arrow.png" className='pointer position-absolute fromTop fromRight'  onClick={right}/>
    </div>
    </>
  )
}

export default Carosel;